<template>
  <div>
    <div class="section-title section-title_app">
      <h3>CAPTURA DE RESULTADOS</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Grabar">
            <b-button icon-left="save"
                      size="is-large"
                      :disabled="readOnly"
                      @click="onSave"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Primer orden">
            <b-button icon-left="circle-arrow-left"
                      size="is-large"
                      @click="navigate('first')"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Orden anterior">
            <b-button icon-left="arrow-left"
                      size="is-large"
                      @click="navigate('preview')"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Orden siguiente">
            <b-button icon-left="arrow-right"
                      size="is-large"
                      @click="navigate('next')"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Última orden">
            <b-button icon-left="circle-arrow-right"
                      size="is-large"
                      @click="navigate('last')"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Filtrar búsqueda"><b-button icon-left="search" size="is-large" type="is-primary" @click="showModal=true"></b-button></b-tooltip>
          <b-tooltip label="Ver PDF de resultados">
            <b-button icon-left="eye" size="is-large" type="is-primary" :disabled="!(order && order.results && order.results.length)" @click="showLink = true"/>
          </b-tooltip>
        </div>
      </div>
      <div class="column is-12 is-7-desktop is-8-widescreen is-9-fullhd has-background-white is-multiline">
        <div class="is-divider" data-content="Orden"></div>
          <div class="columns is-12 column is-multiline">
            <div class="column is-2">
              <b-field label="Año"
                       label-position="on-border">
                <b-select placeholder="2022" v-model="year">
                  <option :value="year.value" :key="year.value" v-for="year in yearsRange">{{year.text}}</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="Orden"
                       label-position="on-border">
                <b-input v-model="order_no"
                         @keypress.native.enter="onSearch"/>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="Folio"
                       label-position="on-border">
                <b-input v-model="order.folio" disabled/>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Fecha"
                       label-position="on-border">
                <b-input disabled :value="order.date_time_formatted"/>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Sucursal"
                       label-position="on-border">
                <b-select disabled v-model="order.branch_office">
                  <option :value="branchOffice.id" :key="branchOffice.id"
                          v-for="branchOffice in branchOffices">{{branchOffice.name}}</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="No.local"
                       label-position="on-border">
                <b-input v-model="order.no" disabled/>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="Fecha de entrega"
                       label-position="on-border">
                <b-input disabled v-model="order.delivery_date_formatted"/>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Empresa" v-if="order && !order.company">
                <b-input disabled/>
              </b-field>
              <b-tooltip :label="order.company.name ? order.company.name : 'Empresa'" v-else>
                <b-field label="Empresa">
                  <b-input v-model="order.company.name" disabled/>
                </b-field>
              </b-tooltip>
            </div>
            <div class="column is-3">
              <b-field label="Estatus">
                {{order.status}}
              </b-field>
            </div>

            <div class="column is-2">
              <b-field>
                <b-checkbox
                    :disabled="signCheckboxReadOnly"
                    v-model="order.has_sign"
                    @input="onChangeSign">Firmada</b-checkbox>
              </b-field>
            </div>
            <div class="box has-background-color" v-if="order.sign_status === 'running'" style="background-color: #fff1b3; border-left: 4px #ffe982 solid; margin-left: 10px; margin-right: 10px; margin-bottom: 10px">
              La firma de resultados está en proceso por favor espere
              <b-icon icon="spinner" style="animation: spinAround 1s infinite"/>
            </div>
          </div>
        </div>

      <div class="column is-5-desktop is-4-widescreen is-3-fullhd has-background-white"
           style="border-left: 2px #dbdbdb solid">
        <div class="is-divider" data-content="Paciente"></div>
        <div class="box has-background-color" style="background-color: #eaf2f8; border-left: 4px #2e86c1 solid; margin-left: 10px; margin-right: 10px; margin-bottom: 10px">
          <p><strong>Clave: </strong><span v-if="order.patient">{{order.patient.code}}</span></p>
          <p><strong>Nombre: </strong><span v-if="order.patient">{{order.patient.full_name}}</span></p>
          <p><strong>Empleado: </strong><span v-if="order.patient">{{order.patient.employee}}</span></p>
          <p><strong>Ubicación: </strong><span v-if="order.location">{{order.location.name}}</span></p>
          <p><strong>Sexo: </strong><span v-if="order.patient">{{order.patient.gender_str}}</span></p>
          <p><strong>Edad: </strong><span v-if="order.patient">{{order.patient.age}}</span></p>
        </div>

      </div>

      <div class="column is-12 has-background-white">
        <section>
          <b-tabs type="is-boxed" v-model="activeTab">
            <b-tab-item label="Estudios" icon="dna">
              <b-table
                  :data="order.order_studies"
                  striped
                  bordered
                  narrowed
                  sticky-header
                  height="100%"
                  :row-class="getRowClass"
              >
                <b-table-column field="study_code" label="Estudio" v-slot="props">
                  {{ props.row.study_code }}
                </b-table-column>
                <b-table-column field="substudy_code" label="SubEstudio" v-slot="props">
                  {{ props.row.substudy.code }}
                </b-table-column>
                <b-table-column field="side" label="Lado" v-slot="props">
                      {{ props.row.side }}
                </b-table-column>
                <b-table-column field="name" label="Descripción" v-slot="props">
                  {{ props.row.substudy.name }}
                </b-table-column>
                <b-table-column field="value" label="Resultado" v-slot="props">
                  <b-select
                      :disabled="readOnly"
                      @input="val => {onUpdateResult(val, props.row)}"
                      v-model="props.row.value"
                      placeholder="">
                    <option value=""></option>
                    <option value="Positivo">Positivo</option>
                    <option value="Negativo">Negativo</option>
                  </b-select>
                  {{ props.row.code }}
                </b-table-column>
                <b-table-column field="witness" label="Testigo" v-slot="props">
                  {{ props.row.witness }}
                </b-table-column>
                <b-table-column field="not_normal" label="A" v-slot="props">
                  {{ props.row.not_normal == 1 ? 'S': '' }}
                </b-table-column>
                <b-table-column field="frecuency" label="Frecuente" v-slot="props">
                  {{ props.row.frecuency }}
                </b-table-column>
                <b-table-column field="print" label="I" v-slot="props">
                  <b-select
                      :disabled="readOnly"
                      v-model="props.row.print">
                    <option value="S">S</option>
                    <option value="N">N</option>
                  </b-select>
                </b-table-column>
                <b-table-column field="reference_value" label="Valor de Ref" v-slot="props">
                  {{ props.row.reference_value }}
                </b-table-column>
                <b-table-column field="delivered_days" label="V" width="30" v-slot="props" v-if="false">
                  {{ props.row.delivered_days }}
                </b-table-column>
                <b-table-column field="observations" label="O" width="30" v-slot="props">
                  <b-button icon-left="eye"
                  @click="() => {showObservations=true; orderStudySelected = props.row}">
                  </b-button>
                </b-table-column>
                <b-table-column field="new_page" label="O.H" width="30" v-slot="props">
                  <b-select
                      :disabled="readOnly"
                      v-model="props.row.new_page">
                    <option value="1">S</option>
                    <option value="0">N</option>
                  </b-select>
                </b-table-column>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon
                            icon="box-open"
                            size="is-large">
                        </b-icon>
                      </p>
                      <p>No hay ordenes seleccionadas.</p>
                    </div>
                  </section>
                </template>
              </b-table>

            </b-tab-item>
            <b-tab-item label="Observaciones" icon="pen">
              <b-field label="Del resultado">
                <b-input  :disabled="readOnly"
                         maxlength="200" type="textarea" v-model="order.observations_result"/>
              </b-field>

              <b-field label="De la admisión">
                <b-input
                    :disabled="readOnly"
                    maxlength="200" type="textarea" v-model="order.observations_admission"/>
              </b-field>
            </b-tab-item>
            <b-tab-item label="Histórico" icon="history" v-if="order.id">
              <b-tabs>
                <b-tab-item label="Nueva" v-if="order.id">
                  <OrderHistory :order="order" v-if="order.id && !loading"/>
                </b-tab-item>
                <b-tab-item label="Web" v-if="order.id">
                  <OrderHistoryWeb :order="order" v-if="order.id && !loading"/>
                </b-tab-item>
              </b-tabs>
            </b-tab-item>
          </b-tabs>
        </section>
      </div>
    </div>
    <b-modal :active.sync="showModal">
      <filter-search-order-full
          :search-params="searchParams"
          @select="onSelectOrder"
      />
    </b-modal>
    <b-modal v-model="showLink"
             width="80vw"
              >
     <download-link :order="order"
              />
    </b-modal>
    <b-modal v-model="showObservations"
            width="80vw">
      <observations-study-order
          v-if="orderStudySelected.id"
          :readOnly="readOnly"
          :order-study="orderStudySelected"
        />
    </b-modal>
  </div>

</template>

<script>
import {mapState, mapGetters} from 'vuex';
import FilterSearchOrderFull from "@/components/order/FilterSearchOrderFull";
import DownloadLink from "@/components/results/DownloadLink";
import ObservationsStudyOrder from "@/components/results/ObservationsStudyOrder";
import OrderHistory from "@/components/order/OrderHistory";
import OrderHistoryWeb from "@/components/order/OrderHistoryWeb";

export default {
  name: "Capture",
  components: {
    DownloadLink,
    FilterSearchOrderFull,
    ObservationsStudyOrder,
    OrderHistory, OrderHistoryWeb
  },
  props: {},
  data() {
    return {
      apiUrl: 'orders',
      showLink: false,
      showObservations: false,
      results: [],
      order_no: '',
      year: (new Date()).getFullYear() % 10,
      showModal: false,
      searchParams: {
        capture: true,
        only_antigenos_sars: true
      },
      interval: null,
      orderStudySelected: {},
      activeTab: 0,
      loading: false
    }
  },
  computed: {
    ...mapState('capture', ['order']),
    branchOffices() {
      return this.$store.state.main.accountBranchOffices;
    },
    yearsRange() {
      return this.$alfalab.yearsRange();
    },
    readOnly() {
      if (!(this.order && this.order.id)) {
        return true;
      }
      return !!this.order.sign_status && (this.order.sign_status === 'running' || this.order.sign_status === 'completed');
    },
    signCheckboxReadOnly() {
      if (!(this.order && this.order.id)) {
        return true;
      }
      return !!this.order.sign_status && (this.order.sign_status === 'running');
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
  },
  methods: {
    navigate(type) {
      this.activeTab = 0;
      if(this.interval){
        clearInterval(this.interval)
        this.interval = null;
      }
      this.$loader.show();
      const params = {year: this.year, only_antigenos_sars: true};
      if (this.order && this.order.order_new) {
        params['order'] = this.order.order_new;
      }
      this.$http.get(`/orders/navigate/${type}`, {
        params
      }).then(async ({data}) => {
        await this.$store.dispatch('capture/setOrder', {});
        await this.$store.dispatch('capture/setOrder', data.data);
        this.order_no = data.data.no;
        this.enableInterval(data.data);
      }).catch(errors => {
        this.$alfalab.showError(errors);
        this.$store.dispatch('capture/setOrder', {});
      }).finally(() => {
        this.$loader.hide();
      })
    },
    onSearch() {
      this.activeTab = 0;
      if(this.interval){
        clearInterval(this.interval)
        this.interval = null;
      }
      this.$loader.show();
      const order = this.year + this.order_no.padStart(7);
      const params = {
        params: {capture: true, only_antigenos_sars: true}
      };
      this.$http.get(`orders/${order}`, params).then(async ({data}) => {
        await this.$store.dispatch('capture/setOrder', {});
        await this.$store.dispatch('capture/setOrder', data.data);
        this.enableInterval(data.data);
      }).catch(errors => {
        this.$alfalab.showError(errors);
        this.$store.dispatch('capture/setOrder', {});
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onSelectOrder(order) {
      this.order_no = order.no;
      this.$store.dispatch('capture/setOrder', order);
    },
    onUpdateResult(value, row) {
      const newData = this.order.order_studies.map(item => {
        if (item.id !== row.id) {
          return item;
        }
        const not_normal = value === 'Positivo' ? 1 : 0;
        return Object.assign({}, item, {value, not_normal});
      });
      this.$store.dispatch('capture/setOrder', Object.assign({}, this.order, {order_studies: newData}));
    },
    getRowClass(row, index) {
      const result = [];
      if (row.not_normal == 1) {
        result.push('row_not_normal');
      }
      if(!row.sample){
        result.push('row_no_editable');
      }
      return result.join(' ');
    },
    onSave() {
      this.loading = true;
      this.$buefy.dialog.confirm({
        title: 'Grabar resultados',
        message: '¿Estas seguro de guardar los resultados?',
        confirmText: 'Si, guardar',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.put(`/orders/${this.order.id}/results`, {...this.order, ui_version: this.$alfalab.version()}).then(({data}) => {
            this.$toast.success('Resultados actualizados con éxito');
          }).catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$loader.hide();
            this.loading = false;
          })
        }
      })
    },
    onChangeSign(val){
      if(this.order && this.order.id){
        let message = '¿Estas seguro de firmar los resultados?';
        if(!val){
           message = '¿Estas seguro de quitar la firmar de los resultados?';
        }
        this.$buefy.dialog.confirm({
          title: 'Firma de Resultados',
          message: message,
          confirmText: 'Si, firmar',
          cancelText: 'No, cancelar',
          onConfirm: () => {
            this.loading = true;
            this.$loader.show();
            this.$http.post(`/orders/${this.order.id}/results-sign`, {sign_value: val, ui_version: this.$alfalab.version()}).then(({data}) => {
              this.$toast.success('Se esta procesando la solicitud espere un momento por favor');
              this.$store.dispatch('capture/setOrder', data.data);
              this.enableInterval(data.data);
            }).catch(errors => {
              this.$alfalab.showError(errors);
            }).finally(() => {
              this.$loader.hide();
              this.loading = false;
            })
          },
          onCancel: () => {
            const newValue = Object.assign({}, this.order, {has_sign: !this.order.has_sign});
            this.$store.dispatch('capture/setOrder', newValue)
          }
        })
      }
    },
    enableInterval(data){
      const params = {year: this.year, only_antigenos_sars: true, capture: true};
      if(data.sign_status === 'running'){
        const intervalCallback = () => {
           this.$http.get(`orders/${data.order_new}`, {params}).then(({data}) => {
            if(data.data.sign_status !== 'running'){
              this.$store.dispatch('capture/setOrder', data.data);
              clearInterval(this.interval);
              this.interval = null;
            }
          }).catch(errors => {
            this.$alfalab.showError(errors);
          })
        }
        this.interval = setInterval(intervalCallback,15000);
      }
    },
    clearOrder() {
      this.$loader.show();
      this.$store.dispatch('capture/clearOrder').finally(() => {
        this.$loader.hide();
      });
    }
  },
  beforeDestroy(){
    this.clearOrder();
  }
}
</script>

<style scoped>
</style>
